import * as React from "react"
import Accordion from "./Accordion"

const faqs = [
  {
    question: "How do you estimate the carbon footprint of a website?",
    answer:
      "We estimate the carbon footprint of a website by analysing a its performance and hosting. We then combine this with data from third parties such as the Green Web Foundation and industry research to estimate carbon output using statistics such as the average carbon produced by rate of data transfer.",
  },
  {
    question: "Do I need to provide a credit/debit card to create an account?",
    answer:
      "No! You can start using DigitalFootprint.earth without a card and only need to provide one if you wish to upgrade your subscription.",
  },
  {
    question: "Can I change my subscription after I start any of the plans?",
    answer:
      "Yes! You can update your subscription at any time and choose one of the larger product tiers depending on your needs - and if you have questions at all we're here to help!",
  },
]

const FrequentlyAskedQuestions = () => {
  return (
    <div className="max-w-5xl mx-auto divide-y-2 divide-gray-200 dark:divide-gray-500 py-24">
      <h2 className="text-center text-3xl font-extrabold text-primary sm:text-4xl">
        Frequently asked questions
      </h2>
      <dl className="mt-6 space-y-6 divide-y divide-gray-200 dark:divide-gray-500">
        {faqs.map(({ question, answer }) => (
          <Accordion question={question} answer={answer} key={question} />
        ))}
      </dl>
    </div>
  )
}

export default FrequentlyAskedQuestions
