import * as React from "react"

import { CheckIcon } from "@heroicons/react/outline"

const ProTier = () => {
  const proFeatures = [
    "Audits for up to 20 pages",
    "Create stories on demand",
    "Generate up to 20 badges",
    "Automated tracking and statistics",
    "Top-tier support",
  ]

  return (
    <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
      <div className="relative z-10 rounded-lg shadow-xl">
        <div
          className="pointer-events-none absolute inset-0 rounded-lg border-2 border-indigo-600"
          aria-hidden="true"
        />
        <div className="absolute inset-x-0 top-0 transform translate-y-px">
          <div className="flex justify-center transform -translate-y-1/2">
            <span className="inline-flex rounded-full bg-indigo-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">
              Most popular
            </span>
          </div>
        </div>
        <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
          <div>
            <h3
              className="text-center text-3xl font-semibold text-gray-900 sm:-mx-6"
              id="tier-pro"
            >
              Professional
            </h3>
            <div className="mt-4 flex items-center justify-center">
              <span className="px-3 flex items-start text-6xl tracking-tight text-gray-900 sm:text-6xl">
                <span className="mt-2 mr-2 text-4xl font-medium">$</span>
                <span className="font-extrabold">11</span>
              </span>
              <span className="text-2xl font-medium text-gray-500">/month</span>
            </div>
          </div>
        </div>
        <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
          <ul role="list" className="space-y-4">
            {proFeatures.map(feature => (
              <li key={feature} className="flex items-start">
                <div className="flex-shrink-0">
                  <CheckIcon
                    className="flex-shrink-0 h-6 w-6 text-indigo-500"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-3 text-base font-medium text-gray-500">
                  {feature}
                </p>
              </li>
            ))}
          </ul>
          <div className="mt-10">
            <div className="rounded-lg shadow-md">
              <a
                href="#"
                className="block w-full text-center rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-indigo-700"
                aria-describedby="tier-pro"
              >
                Start trial
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProTier
