import * as React from "react"

import { CheckIcon } from "@heroicons/react/outline"

const TierOne = () => {
  const hobbyFeatures = [
    "Audit up to three pages",
    "Navigate stories for 1000+ sites",
    "Single badge generation access",
  ]

  return (
    <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
      <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
        <div className="flex-1 flex flex-col">
          <div className="bg-white px-6 py-10">
            <div>
              <h3
                className="text-center text-2xl font-medium text-gray-900"
                id="tier-hobby"
              >
                Hobby
              </h3>
              <div className="mt-4 flex items-center justify-center">
                <h4 className="px-3 text-5xl tracking-tight text-gray-900 font-extrabold">
                  Free
                </h4>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
            <ul role="list" className="space-y-4">
              {hobbyFeatures.map(feature => (
                <li key={feature} className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckIcon
                      className="flex-shrink-0 h-6 w-6 text-blue-500"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-3 text-base font-medium text-gray-500">
                    {feature}
                  </p>
                </li>
              ))}
            </ul>
            <div className="mt-8">
              <div className="rounded-lg shadow-md">
                <a
                  href="#"
                  className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 font-semibold text-blue-600 hover:bg-gray-50"
                  aria-describedby="tier-hobby"
                >
                  Start trial
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TierOne
