import * as React from "react"

import Layout from "../components/root/Layout"
import ComparisonTable from "../components/ComparisonTable/ComparisonTable"
import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions"

const Pricing = () => {
  return (
    <Layout>
      <div className="text-center flex flex-col space-y-4 my-16 bg-primary ">
        <h2 className="text-6xl font-extrabold text-primary">
          Pricing
        </h2>
        <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-500 dark:text-gray-300 sm:mt-5 sm:text-2xl">
          Start auditing for free with up to three pages and start a
          subscription to unlock more and make a commitment to improving the
          digital footprint of your business.
        </p>
      </div>
      <ComparisonTable />
      <FrequentlyAskedQuestions />
    </Layout>
  )
}

export default Pricing
