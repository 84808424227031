import * as React from "react"

import HobbyTier from "./HobbyTier"
import ProTier from "./ProTier"
import Enterprise from "./EnterpriseTier"

const ComparisonTable = () => {
  return (
    <div className="relative z-0">
      <div className="absolute inset-0 h-5/6 dark:bg-gray-900 lg:h-2/3" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative lg:grid lg:grid-cols-7">
          <HobbyTier />
          <ProTier />
          <Enterprise />
        </div>
      </div>
    </div>
  )
}

export default ComparisonTable
