import * as React from "react"

import { m as motion, AnimatePresence } from "framer-motion"

import { ChevronDownIcon } from "@heroicons/react/outline"

const Accordion = ({ question, answer }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <motion.section
      key={question}
      className="pt-7"
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={{
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 },
      }}
      transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
    >
      <motion.dt
        initial={false}
        onClick={() => setOpen(open ? false : true)}
        className="text-lg cursor-pointer"
      >
        <div className="text-left w-full flex justify-between items-start text-gray-400 dark:text-gray-300">
          <span className="font-medium text-primary">
            {question}
          </span>

          <motion.div
            className="w-6 h-6"
            animate={{
              rotate: open ? [0, 0, 180] : [180, 180, 0],
            }}
            transition={{
              ease: "easeInOut",
              times: [0, 0.1, 0.35],
            }}
          >
            <ChevronDownIcon className={`h-6 w-6`} aria-hidden="true" />
          </motion.div>
        </div>
      </motion.dt>
      <AnimatePresence initial={false}>
        {open && (
          <motion.section
            className="pr-12"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ type: "spring", duration: 0.4, ease: "easeInOut" }}
          >
            <p className="text-gray-400">{answer}</p>
          </motion.section>
        )}
      </AnimatePresence>
    </motion.section>
  )
}

export default Accordion
